<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Movimientos</h4>
            <div class="small text-muted">Consultar movimientos contables.</div>
          </b-col>
          <b-col sm="5" v-if="filters.finances_cash_id">            
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>            
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" v-if="table.mostrarFiltros">
        <b-row>
          <b-col sm="10">
            <b-row :key="keyForceUpdate">
              <b-col sm="3">
                <FindObject render="search"
                            type="cash" 
                            @select-object="loadCash($event)" 
                            :showAllRegister="true"
                            :valueID="filters.finances_cash_id"/>
              </b-col>   

              <b-col md="2">
                <b-form-group label="Desde">    
                  <b-form-input type="date" size="sm" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                  <b-form-timepicker size="sm" now-button reset-button v-model="filters.time_start" locale="es"></b-form-timepicker>
                </b-form-group>
              </b-col>              

              <b-col md="2">                  
                <b-form-group label="Hasta">    
                  <b-form-input type="date" size="sm" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                  <b-form-timepicker size="sm" now-button reset-button v-model="filters.time_end" locale="es"></b-form-timepicker>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <FindObject render="search"
                            type="currency" 
                            @select-object="loadCurrency($event)" 
                            :valueID="filters.currency_id"/>
              </b-col>

              <b-col sm="2" class="mt-4">
                <b-button variant="outline-dark" size="sm" @click="filterReport()">
                  <b-icon icon="filter" size="sm" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                     
            </b-row>
          </b-col>
          <b-col sm="2" class="p-1">
            <b-row>
              <b-col sm="12">                                 
                <b-link>
                  <export-excel
                    class = 'pull-right mt-3'
                    :data = "arr.export"
                    worksheet = "Listado de movimientos de caja/cuenta"
                    name = "list-cash.xls">
                    Exportar Datos
                  </export-excel>            
                </b-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      
      <b-row class="p-0 cash-detail-accion" v-if="filters.finances_cash">        
        <b-col md="6">
          <div v-if="filters.finances_cash.only_open">
            <span v-if="filters.finances_cash.opening" class="table-cash-status-open">
              <b-icon icon="unlock"></b-icon>
              ABIERTA (<span v-html="statusCash(filters.finances_cash)"></span>)
            </span>            
            <span v-else class="table-cash-status-close">
              <b-icon icon="lock"></b-icon>
              CERRADA
            </span>                        

            <b-button @click="searchCash()" type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" v-b-tooltip.hover title="Refrescar">              
              <i class="fa fa-refresh"></i> Refrescar
            </b-button>            
          </div>          
        </b-col>
        <b-col md="6">
          <b-button-group class="pull-right">
            <b-button variant="success" @click="goTo('positive-adjustment')">
              <b-icon icon="plus-square"></b-icon> Ajuste Positivo
            </b-button>
            <b-button variant="danger" @click="goTo('negative-adjustment')">
              <b-icon icon="dash-square"></b-icon> Ajuste Negativo
            </b-button>
            <b-button variant="info" @click="goTo('transfer')">
              <b-icon icon="arrow-left-right"></b-icon> Transferir
            </b-button>
          </b-button-group>            
        </b-col>
      </b-row>

      <b-card header-tag="header" footer-tag="footer" class="p-0 cash-detail-movimientos" v-if="filters.finances_cash">
        <b-row>
          <b-col>
            <b-table class="m-0 p-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"                    
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :items="table.items"
                    v-if="table.items.length">

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 

              <template v-slot:cell(date)="data">
                <div v-if="data.item.date_time">
                  {{ moment(data.item.date_time).format('DD MMMM YYYY') }}
                </div>
              </template>

              <template v-slot:cell(time)="data">
                <div v-if="data.item.date_time">
                  {{ moment(data.item.date_time).format('HH:mm') }}
                </div>
              </template>

              <template v-slot:cell(origen)="data">
                <span v-if="data.item.origen.customer"><b>CLIENTE:</b> {{data.item.origen.customer}}</span>
                <span v-if="data.item.origen.supplier"><b>PROVEEDOR:</b> {{data.item.origen.supplier}}</span>
              </template>

              <template v-slot:cell(reference)="data">                                                                                
                <div v-if="!Array.isArray(data.item.origen)" style="cursor: pointer;">                  
                  <div v-if="data.item.origen.customer">                      
                    <div :id="'customer-extra-'+data.item.id">                    
                      {{data.item.reference}}
                    </div>
                    <b-popover :target="'customer-extra-'+data.item.id" triggers="hover" placement="top">
                      <template #title>Datos de Recibo</template>

                      <span v-if="data.item.origen.point_sale">
                        {{data.item.origen.types_vouchers}} <br>
                        {{data.item.origen.point_sale.toString().padStart(4,'0') + '-' + data.item.origen.number.toString().padStart(8,'0')}} 
                        <hr>
                        {{moment(data.item.origen.date).format('DD MMMM YYYY')}} <br>
                        ({{data.item.origen.customer_id}}) - {{data.item.origen.customer}} 
                        <hr>
                        {{ data.item.origen.observations }}                      
                      </span>
                    </b-popover>                                      
                  </div>
                  
                  <div v-if="data.item.origen.supplier">
                    <div :id="'supplier-extra-'+data.item.id">                    
                      {{data.item.reference}}
                    </div>
                    <b-popover :target="'supplier-extra-'+data.item.id" triggers="hover" placement="top">
                      <template #title>Datos de Pago</template>                    

                      <span v-if="data.item.origen.point_sale">
                        {{data.item.origen.types_vouchers}} <br>
                        {{data.item.origen.point_sale.toString().padStart(4,'0') + '-' + data.item.origen.number.toString().padStart(8,'0')}} 
                        <hr>
                        {{moment(data.item.origen.date).format('DD MMMM YYYY')}} <br>
                        ({{data.item.origen.supplier_id}}) - {{data.item.origen.supplier}} 
                        <hr>
                        {{ data.item.origen.observations }}
                      </span>
                    </b-popover> 
                  </div>                  
                </div>    
                <div v-else>
                  {{data.item.reference}}
                </div>            
              </template>

              <template v-slot:cell(amount)="data">
                <span v-if="data.item.type_balance=='+'" class="cash-detail-text-success">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount)}}
                </span>
                <span v-if="data.item.type_balance=='-'" class="cash-detail-text-danger">
                  ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount)}})
                </span>
              </template>

              <template v-slot:cell(f_action)="data">                
                <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right" v-if="data.item.manual">
                  <b-dropdown-item @click="remove(data.item)">
                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <b-alert v-else variant="warning" show class="m-0">No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-row align-h="end" v-if="table.items.length && filters.finances_cash" :key="keyForceUpdate">
        <b-col sm="4">      
          <b-card header-tag="header" footer-tag="footer" class="p-0 cash-detail-totales">
            <b-table-simple hover small responsive bordered class="m-0 p-0">             
              <b-thead head-variant="dark">                                          
                <b-tr>                      
                  <b-th width="30%" class="text-right">
                    <div class="mr-1">Moneda</div>                        
                  </b-th>
                  <b-th width="35%" class="text-right">
                    <div class="mr-1">Total</div>
                  </b-th>                                
                  <b-th width="35%" class="text-right">
                    <div class="mr-1">Resumen</div>                        
                  </b-th>                                    
                </b-tr>
              </b-thead>  
              <b-tbody> 
                <b-tr v-for="(element, index) in this.getTotalByCurrency()" :key="index">                                       
                  <b-td class="text-right align-middle">        
                    <div class="mr-1">
                      <b>{{element.currency_name}}</b>
                    </div>            
                  </b-td>                  
                  <b-td class="text-right align-middle cash-detail-amount-totals">   
                    <div class="mr-1" v-if="filters.finances_cash" :class="{'cash-detail-text-danger':parseFloat(getTotalCash(element.currency_id))<0, 'cash-detail-text-success':parseFloat(getTotalCash(element.currency_id))>=0}">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.currency_code}).format( getTotalCash(element.currency_id) )}}
                    </div>            
                  </b-td>
                  <b-td class="text-right align-middle">        
                    <div class="mr-1 cash-detail-amount-totals" :class="{'cash-detail-text-danger':parseFloat(element.currency_amount)<0, 'cash-detail-text-success':parseFloat(element.currency_amount)>=0}">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.currency_code}).format( element.currency_amount )}}
                    </div>            
                  </b-td>                                    
                </b-tr>
              </b-tbody>
            </b-table-simple>            
          </b-card>
        </b-col>
      </b-row>
      
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
      
          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                              size="sm"
                              pills=""
                              :total-rows="table.tablePaginate.total"
                              :per-page="table.tablePaginate.per_page"
                              v-model="table.currentPage"
                              @input="filterReport()" />
            </nav>
          </b-col>  
        </b-row>
      </b-card>


      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              size="lg"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>                     
          <b-col sm="12">
            <FindObject render="search"
                        type="cash"    
                        tag="Origen"                     
                        :valueID="crud.form.finances_cash_id"
                        :showAllRegister="true"
                        :disabled="true"/>
          </b-col>

          <b-col sm="12" v-if="crud.form.event == 'transfer'">
            <FindObject render="search"
                        type="cash"                      
                        tag="Destino"                        
                        @select-object="loadCashTransfer($event)"                                                 
                        :valueID="crud.form.finances_cash_transfer_id"
                        :showAllRegister="true"
                        :key="keyForceUpdateCashTransfer"/>
          </b-col>          

          <b-col md="6">
            <b-form-group label="Fecha">
              <b-form-datepicker size="sm"  
                                 v-model="crud.form.date" 
                                 placeholder="Fecha" 
                                 local="es">
              </b-form-datepicker>             
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Hora">
              <b-form-timepicker size="sm" 
                                 v-model="crud.form.time"  
                                 now-button 
                                 reset-button 
                                 locale="es">
              </b-form-timepicker>         
            </b-form-group>
          </b-col>
        
          <b-col md="8">
            <span v-if="crud.form.event == 'adjustment'">
              <b-alert show variant="danger" v-if="crud.form.type_balance=='-'">
                <h4 class="alert-heading">{{this.modal.form.title}}</h4>
                El importe ingresado, se mostrará con saldo Negativo <b>(-)</b>
              </b-alert>
              <b-alert show variant="success" v-if="crud.form.type_balance=='+'">
                <h4 class="alert-heading">{{this.modal.form.title}}</h4>
                El importe ingresado, se mostrará con saldo Positivo <b>(+)</b>
              </b-alert>            
            </span>
            <span v-if="crud.form.event == 'transfer'">
              <b-alert show variant="info">
                <h4 class="alert-heading">{{this.modal.form.title}}</h4>
                Se genera un movimiento de salida en "ORIGEN" y una entrada en "DESTINO"
              </b-alert>
            </span>
          </b-col>
          <b-col md="4">
            <b-form-group label="Importe">
              <b-input-group prepend="$">            
                <b-form-input v-model="crud.form.amount" 
                              type="number" 
                              setp="0.01" 
                              min="0.00">
                </b-form-input>            
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Referencia">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.reference"
                            required
                            placeholder="Ingresar una referencia">
              </b-form-input>
            </b-form-group>
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>      

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Storage from '@/handler/storageLocal'
  import Vue from 'vue'
  import FindObject from '@/components/inc/find/findObject' 

  export default {
    components: {
      FindObject,
    }, 
    data: () => {
      return {      
        access: {
          module_id: Modules.FINANZAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'cashDetail',
          elements: {}
        }, 
        table : {
          items: [],
          filters: null,
          fields: [            
            {key: 'id', label: 'ID', class: "align-middle", width:"5%"},
            {key: 'date', label: 'Fecha', class: "align-middle", width:"10%"},
            {key: 'time', label: 'Hora', class: "align-middle", width:"5%"},
            {key: 'reference', label: 'Referencia', class: "align-middle", width:"30%"},
            {key: 'origen', label: 'Origen', class: "align-middle", width:"30%"},            
            {key: 'amount', label: 'Importe', class: "align-middle text-right", width:"18%"},
            {key: 'f_action', label:'', class: "align-middle", width:"2%"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          tablePaginate: null,          
        },          
        arr: {
          export: [],
        },
        filters: {
          finances_cash: null,
          finances_cash_id: 0,
          finances_cash_transfer: null,
          finances_cash_transfer_id: 0,
          currency: null,
          currency_id: 0,
          date_start: '',
          time_start: '',
          date_end: '',
          time_end: '',
        }, 
        crud: {
          form: {            
            finances_cash_id: 0,
            finances_cash: null,            
            currency_id: 0,
            currency: null,
            date: '',
            time: '',
            reference: '',
            amount: 0,
            manual: true,
            event: 'adjustment'
          },                 
        },        
        modal: {
          form: {
            active: false,
            title: ''
          },        
        },         
        keyForceUpdate: 0,   
        keyForceUpdateCashTransfer: 0,   
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.searchCash()            
    },
    methods: {
      searchCash() {                
        var result = serviceAPI.obtenerCajas()

        result.then((response) => {
          var data = response.data

          if(data.length) {     
            this.filters.finances_cash = data[0]
            this.filters.finances_cash_id = data[0].id
          }    

          this.filterLoad()      
          this.restoreFilterStorage() 

          this.forceUpdate()                    
          this.filterReport()          
        })                  
      },      
      filterLoad() { 
        this.filters.date_start = moment().format('YYYY-MM-DD')  
        this.filters.time_start = null  
        this.filters.date_end = moment().format('YYYY-MM-DD') 
        this.filters.time_end = null  
      },
      loadCash (object) {        
        if(object){
          this.filters.finances_cash = object
          this.filters.finances_cash_id = object.id    
          
          this.filterReport()
        } else {
          this.filters.finances_cash = null
          this.filters.finances_cash_id = 0
        }
      }, 
      loadCurrency (object) {        
        if(object){
          this.filters.currency = object
          this.filters.currency_id = object.id        
        } else {
          this.filters.currency = null
          this.filters.currency_id = 0
        }
      }, 
      filterReport(){
        this.saveFilterStorage()     

        if(!this.filters.finances_cash_id) {
          this.$awn.alert("Debe seleccionar una caja/cuenta");
          return false;
        }

        let loader = this.$loading.show()   
            
        var result = serviceAPI.filtrarCajaDetalle(this.filters, this.table.currentPage)        

        result.then((response) => {
          loader.hide()          
          this.table.items = response.data.data

          this.prepareExport(response.data.data)
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },

      getTotalByCurrency() {        
        var arrTotal = []

        if(this.table.items.length) {
          this.table.items.forEach(element=>{
            var code = element.currency.id     
            arrTotal[code] = null
            
            arrTotal[code] = {
              currency_id: element.currency.id,
              currency_name: element.currency.name,
              currency_code: element.currency.code,
              currency_amount: 0,
            }
          })          
        }
        
        this.table.items.forEach(element=>{                 
          var code = element.currency.id          

          if(element.type_balance == '+') {
            arrTotal[code].currency_amount = parseFloat(arrTotal[code].currency_amount) + parseFloat(element.amount)
          } else {
           arrTotal[code].currency_amount = parseFloat(arrTotal[code].currency_amount) - parseFloat(element.amount) 
          }          
        })

        arrTotal = arrTotal.filter((obj) => { return ![null, undefined].includes(obj) })

        return arrTotal        
      },
      getTotalCash($currency_id) {        
        var total = 0
        if(this.filters.finances_cash) {
          this.filters.finances_cash.total_available.forEach(element => {
            if(element.currency_id == $currency_id) {
              total = element.amount
            }            
          }); 
        }
        return total
      },

      forceUpdate() {
        this.keyForceUpdate = this.keyForceUpdate + 1
      },          
      forceUpdateCashTransfer() {
        this.keyForceUpdateCashTransfer = this.keyForceUpdateCashTransfer + 1
      },                

      goTo(data) {
        switch (data) {
          case 'positive-adjustment':
            this.addAdjustment('+')
            break;
        
          case 'negative-adjustment':
            this.addAdjustment('-')
            break;
            
          case 'transfer':
            this.addTransfer()
            break;                      
        }
      },
      addAdjustment(type_balance) {
        let title = "Ajuste Positivo"
        if(type_balance == '-') {
          title = "Ajuste Negativo"
        }

        this.crud.form.event = 'adjustment'       
        this.crud.form.finances_cash_id = this.filters.finances_cash_id
        this.crud.form.finances_cash = this.filters.finances_cash
        this.crud.form.currency_id = 1
        this.crud.form.currency = null
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.time = ''
        this.crud.form.reference = ''
        this.crud.form.amount = 0
        this.crud.form.type_balance = type_balance

        this.modal.form.title = title
        this.modal.form.active = true
      },   
      addTransfer() {
        this.crud.form.event = 'transfer'       
        this.crud.form.finances_cash_id = this.filters.finances_cash_id
        this.crud.form.finances_cash = this.filters.finances_cash
        this.crud.form.finances_cash_transfer_id = 0
        this.crud.form.finances_cash_transfer = null        
        this.crud.form.currency_id = 1
        this.crud.form.currency = null
        this.crud.form.date = moment().format('YYYY-MM-DD')
        this.crud.form.time = ''
        this.crud.form.reference = ''
        this.crud.form.amount = 0         
        this.crud.form.type_balance = "-"

        this.modal.form.title = "Transferencia"
        this.modal.form.active = true
      },   

      loadCashTransfer (object) {        
        if(object){          
          this.crud.form.finances_cash_transfer = object
          this.crud.form.finances_cash_transfer_id = object.id    

          if(this.crud.form.finances_cash_transfer_id == this.crud.form.finances_cash_id) {
            this.crud.form.finances_cash_transfer = null
            this.crud.form.finances_cash_transfer_id = 0
            this.forceUpdateCashTransfer()

            this.$awn.info("La caja/cuenta origen debe ser distina a la de destino");
            return false
          } 
        } else {
          this.crud.form.finances_cash_transfer = null
          this.crud.form.finances_cash_transfer_id = 0
        }
      },

      save() {
        if(!this.crud.form.reference) {
          this.$awn.warning("Ingresá una referencia");
          return false
        }

        if(parseFloat(this.crud.form.amount)<0) {
          this.$awn.warning("Ingresá una importe mayor a cero (0)");
          return false
        }        

        let loader = this.$loading.show();

        var result = serviceAPI.agregarCajaDetalle(this.crud.form);
        
        result.then((response) => {
          this.modal.form.active = false
          loader.hide()

          this.forceUpdate()                    
          this.filterReport()

          this.$awn.success("Ajuste guardado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      remove(item) {        
        this.$bvModal.msgBoxConfirm('¿Desea borrar el ajuste (' + item.reference + ')?', {
          title: 'Borrar Ajuste',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarCajaDetalle(item.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()

              this.forceUpdate()                    
              this.filterReport()

              this.$awn.success("Ajuste eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_cash_details', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {                        
        if(Storage.getValue('filter_cash_details')) {
          this.filters = JSON.parse(Storage.getValue('filter_cash_details'))
        }         

        let finances_cash_id = this.$route.params.finances_cash_id        
        let date_start = this.$route.params.date_start        
        if(finances_cash_id) {
          this.filters.finances_cash_id = finances_cash_id
          this.filters.date_start = moment(date_start).format('YYYY-MM-DD')
          this.filters.time_start = moment(date_start).format('HH:mm')          
        }
      },    
      prepareExport(data) {        
        this.arr.export = []

        data.forEach(element => {
          var amount = element.amount
          
          if(element.type_balance == '-'){
              amount = element.type_balance + amount
          }

          this.arr.export.push({
            id: element.id,
            date: moment(element.date_time).format('YYYY-MM-DD'),
            time: moment(element.date_time).format('HH:mm'),
            reference: element.reference,
            origen: element.origen.customer ? "CLIENTE: " + element.origen.customer : element.origen.supplier ? "PROVEEDOR: " + element.origen.supplier : '',            
            amount: amount.replace('.',','),
          })
        });        
      },

      statusCash(data) {
        let status = ''
        
        if(data.status.status == 'OPEN') {
          if(data.status.open) {
            status = moment(data.status.open).locale('es').fromNow()
          } else {
            status = moment().locale('es').fromNow()
          }
          
        }

        return status       
      }      
    }    
  }
</script>
<style scoped>
  .cash-detail-amount-totals {
    font-size: 15px;
    color:darkgreen;
    font-weight: 800;
  }
  .cash-detail-text-success {
    color:green;    
  }
  .cash-detail-text-danger {
    color:red;
  }

  .cash-detail-movimientos .card-body {
    padding: 0;
  }
  .cash-detail-totales .card-body {
    padding: 0;
  }
  .cash-detail-accion .card-body {
    padding: 0;
  }

  .table-cash-status-open {
    font-weight: bold;
    font-size: 18px;
    color:orangered;    
  }
  .table-cash-status-close {
    font-weight: bold;
    font-size: 18px;
  }
</style>